/** @jsx jsx this comment is required for Storybook to render */
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';
import Card from '../../atoms/Card/Card';
import IconTextBlock from '../../molecules/IconTextBlock/IconTextBlock';
import {
  breakpoints,
  fontFamilies,
  fontSizes,
  fontWeights,
  headingColors,
  unitConverter as uc,
} from '../../../styles/base';
import Section from '../../molecules/Section/Section';
import ResponsiveContentWrapper from '../ResponsiveContentWrapper/ResponsiveContentWrapper';
import Image from '../../atoms/Image/Image';

const SuiteIconGridSectionCard = ({ children, className }) => {
  const suiteIconGridSectionCss = css`
    padding: ${uc('10px 0 40px')};
    font-family: ${fontFamilies.roboto};
    text-align: center;
  `;

  return (
    <Card css={suiteIconGridSectionCss} className={className}>
      {children}
    </Card>
  );
};

SuiteIconGridSectionCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

SuiteIconGridSectionCard.defaultProps = {
  children: null,
  className: '',
};

const SuiteIconGridSection = ({ iconTextBlocks, sectionStyles, suiteType }) => {
  const iconTextBlockCss = css`
    margin: ${uc('90px 44px 0')};

    @media (${breakpoints.desktop}) {
      margin: ${uc('20px')};
    }

    @media (${breakpoints.mobile}) {
      width: 80%;
      max-width: ${uc('320px')};
      margin: 0;
    }

    h4 {
      margin-bottom: ${uc('6px')};
      color: ${headingColors.blueBlack};
      font-weight: ${fontWeights.medium};
      font-family: ${fontFamilies.roboto};
    }

    p {
      color: ${headingColors.mediumGray};
      font-weight: ${fontWeights.medium};
      font-size: ${fontSizes.fifteen};
      font-family: ${fontFamilies.roboto};
    }

    div {
      height: ${uc('80px')};
      margin: ${uc('0 auto 5px')};

      img {
        margin: auto;
      }
    }
  `;

  const avatarImageCss = css`
    position: absolute;
    left: 50%;
    width: ${uc('100px')};
    transform: translate(-50%, -60%);

    @media (${breakpoints.tablet}) {
      margin-top: ${uc('50px')};
    }

    @media (${breakpoints.mobile}) {
      margin-top: ${uc('20px')};
      transform: translate(-50%, -60%) scale(0.75);
    }
  `;

  const iconGridWrapperCss = css`
    @media (${breakpoints.desktop}) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding: ${uc('60px 0 0 0')};
    }

    @media (${breakpoints.mobile}) {
      padding: ${uc('70px 0 0 0')};
    }
  `;

  const renderedIconTextBlocks = iconTextBlocks.map(iconTextBlock => (
    <IconTextBlock
      key={`icontextblock-component-${iconTextBlock._key}`}
      css={iconTextBlockCss}
      {...iconTextBlock}
    />
  ));

  const suiteIconGridSectionCss = css`
    margin-top: ${uc('75px')};

    @media (${breakpoints.mobile}) {
      margin-top: ${uc('50px')};
    }
  `;

  return (
    <Section sectionStyles={sectionStyles} css={suiteIconGridSectionCss}>
      <ResponsiveContentWrapper>
        <SuiteIconGridSectionCard>
          <Image
            css={avatarImageCss}
            src={`/suiteAvatars/${suiteType}Avatar.svg`}
          />
          <div css={iconGridWrapperCss}>{renderedIconTextBlocks}</div>
        </SuiteIconGridSectionCard>
      </ResponsiveContentWrapper>
    </Section>
  );
};

SuiteIconGridSection.propTypes = {
  iconTextBlocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  suiteType: PropTypes.string,
};

SuiteIconGridSection.defaultProps = {
  sectionStyles: {},
  suiteType: 'learning',
};

export default SuiteIconGridSection;
